import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: "/",
    name: "首页",
    component: Home
  },
  {
    path: "/story",
    name: "生来故事",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Story.vue")
  },
  {
    path: "/product",
    name: "生来产品",
    component: () => import("../views/Product.vue")
  },
  {
    path: "/craft",
    name: "制作工艺",
    component: () => import("../views/Craft.vue")
  },
  {
    path: "/productDetail",
    name: "产品详情",
    component: () => import("../views/News.vue")
  },
  {
    path: "/video",
    name: "视频中心",
    component: () => import("../views/VideoCenter.vue")
  },
  {
    path: "/newsDetail",
    name: "活动新闻",
    component: () => import("../views/NewsDetail.vue")
  }
];

const router = new VueRouter({
  // history模式，去除URL中#
  // mode: "history",
  routes,
  // 解决路由跳转后，不显示在首行
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
