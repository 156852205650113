<template>
  <div>
    <div class="footer">
      <div class="left">
        <div class="leftcontent">
          <div class="title">联系我们</div>
          <p>地址：北京市西城区复兴门内大街156号D座</p>
          <!-- <p>电话：66426993</p> -->
          <p>联系电话：400-066-7268</p>
          <p>邮编：100031</p>
        </div>
      </div>
      <div class="mid">
        <div class="midcontent">
          <div class="title">购买渠道</div>
          <div class="l6" style="">
            <!-- <span class="iconfont  iconweixin"></span> -->
            <span
              @mousemove="imgflag2 = true"
              @mouseout="imgflag2 = false"
              style="position:relative"
              class="iconfont iconxiaochengxu"
            >
              <transition name="slide-fade">
                <img
                  v-if="imgflag || imgflag2"
                  class="qcode"
                  src="../assets/img/qcode.png"
                  alt=""
                />
              </transition>
            </span>
            <a
              class="jd-icon"
              href="http://mall.jd.com/index-12197607.html"
              target="_blank"
            >
              <img src="../assets/img/jd-icon.png" alt="" srcset="" />
            </a>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="footlogo">
          <img
            src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/logo大.png"
            alt=""
          />
        </div>
        <div class="text">中邮普泰 ⅹ 雨林古树茶联合出品</div>
      </div>
    </div>
    <div class="descrip">
      <p>
        主体备案号： Copyright © {{year}} 中国邮电器材集团有限公司 版权所有 |
        <a href="https://beian.miit.gov.cn" target="_blank">
          京ICP备05064806号-3
        </a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgflag: false,
      imgflag2: false,
      year: new Date().getFullYear()
    };
  }
};
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  height: 217px;
  background: #edb55b;
  color: #000000;
  font-family: SourceHanSansCN-Normal;
  display: flex;
  justify-content: space-around;
  padding: 65px 0;
  position: relative;

  .title {
    font-size: 38px;
    margin-bottom: 16px;
  }
  p {
    font-size: 23px;
    line-height: 35px;
  }
  .left {
    width: 33%;

    .leftcontent {
      padding: 35px 70px;
    }
  }
  .mid {
    width: 33%;
    border-right: dotted #000 4px;
    border-left: dotted #000 4px;
    .midcontent {
      padding: 35px 140px;
      .l6 {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 38px;
        .jd-icon {
          margin-left: 29px;
          padding-bottom: 5px;
          img {
            width: 60px;
          }
        }
      }
    }
  }
  .right {
    width: 33%;
    text-align: center;
    .footlogo {
      margin-top: 65px;
      img {
        width: 271px;
      }
    }
    .text {
      font-size: 17px;
      margin-top: 10px;
      color: #333;
    }
  }
}
.descrip {
  width: 100%;
  height: 100px;
  text-align: center;
  background: #090704;
  color: #fff;
  font-size: 22px;
  font-family: SourceHanSansCN-Light;
  padding-top: 50px;
  font-weight: 300;
  a {
    color: #fff;
  }
  a:hover {
    opacity: 0.8;
  }
}
.qcode {
  width: 150px;
  height: 150px;
  position: absolute;
  left: 45px;
  top: -10px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active 用于 2.1.8 以下版本 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
