<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    this.browserRedirect();
  },
  methods: {
    browserRedirect() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      console.log(sUserAgent);
      if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
      ) {
        if (
          window.location.host.includes("test") ||
          window.location.host.includes("localhost")
        ) {
          window.location.href = "http://testmobile.shenglaitea.com/";
        } else if (window.location.host.includes("61.183")) {
          window.location.href = "http://61.183.213.198:31307/";
        } else {
          window.location.href = "http://m.shenglai.com";
        }
        //取消注释，手机设备跳转到手机站主页
        console.log("mobile");
      } else {
        //window.location.href='index.html';
        console.log("window");
      }
    }
  }
};
</script>

<style lang="less">
// url("./assets/思源宋体/SourceHanSerifCN-Bold.otf"),
//     url("./assets/思源宋体/SourceHanSerifCN-ExtraLight.otf"),
//     url("./assets/思源宋体/SourceHanSerifCN-Heavy.otf"),
//     url("./assets/思源宋体/SourceHanSerifCN-Light.otf"),
//     url("./assets/思源宋体/SourceHanSerifCN-Medium.otf"),
//     url("./assets/思源宋体//SourceHanSerifCN-Regular.otf"),
//     url("./assets/思源宋体/SourceHanSerifCN-SemiBold.otf");
// 初始化
@import "../static/css/iconfont.css";
@font-face {
  font-family: "source serif Bold";
  src: url("./assets/思源宋体/SourceHanSerifCN-Bold.otf");
}
@font-face {
  font-family: "source serif light";
  src: url("./assets/思源宋体/SourceHanSerifCN-Light.otf");
}
@font-face {
  font-family: "source serif ExtraLight";
  src: url("./assets/思源宋体/SourceHanSerifCN-ExtraLight.otf");
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
}

#app {
  position: relative;
}
#nav {
  // width: 1920px;
  height: 100%;
  width: 100%;
  background: #2a00004d;
  // opacity: 0.34;
  position: absolute;
  top: 0;
  z-index: 999;
  .logo {
    height: 100%;
    width: 100%;
    background: no-repeat center;
    background-image: url("./assets/img/logo2.png");
    background-size: 135px 190px;
  }
}
</style>
