<template>
  <div>
    <div class="common top">
      <el-carousel
        :interval="2000"
        arrow="never"
        :height="dataHeight"
        autoplay
        loop
      >
        <el-carousel-item v-for="(item, index) in swiperList" :key="index">
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
      <img
        src="../assets/img/arrow.gif"
        class="arrow"
        @click="gotoself('#story')"
        alt=""
      />
      <div class="topleft">
        <img src="../assets/img/logo2.png" alt="" />
        <div class="nav">
          <a
            v-for="(i, index) in navlist"
            :key="i.id"
            :style="{ color: isSelect == index ? '#edb55b' : '' }"
            @mouseover="move(index)"
            @mouseleave="isSelect = 0"
            @click="goto(i.key)"
            >{{ i.title }}</a
          >
        </div>
      </div>
    </div>
    <div class="black"></div>
    <div class="common story" id="story">
      <img src="../assets/img/故事home.jpg" alt="" /><img
        src="../assets/img/arrow.gif"
        class="arrow"
        @click="gotoself('#product')"
        alt=""
      />
      <div class="storyright">
        <div class="storycontent">
          <div class="titlecn">生来故事</div>
          <div class="titleen">The story of ShengLai</div>
          <p>生于飞速运转的时代，</p>
          <p>来往于繁忙的都市间，</p>
          <p>在快节奏的生活之中，</p>
          <p>我们需要</p>
          <p>一方宁静的小天地，</p>
          <p>一盏茶的悠闲时光。</p>
          <div class="btn" style="margin-top: 60px;" @click="toStory">
            Explore
          </div>
        </div>
      </div>
    </div>
    <div class="black"></div>
    <div class="common story" id="product">
      <img src="../assets/img/swiper3.jpg" alt="" /><img
        src="../assets/img/arrow.gif"
        class="arrow"
        @click="gotoself('#craft')"
        alt=""
      />
      <div class="storyright" style="left:0;background: (0,0,0,0.63);">
        <div class="storycontent">
          <div class="titlecn">生来产品</div>
          <div class="titleen">The products of ShengLai</div>
          <p>P系列-普洱生茶</p>
          <p>T系列-普洱熟茶</p>
          <p>A系列-古树白茶</p>
          <p>C系列-古树红茶</p>
          <p>生来不凡</p>
          <div class="btn" @click="toProduct">Explore</div>
        </div>
      </div>
    </div>
    <div class="black"></div>
    <div class="common story" id="craft">
      <img src="../assets/img/工艺home.jpg" alt="" />
      <div class="storyright" style="background: (0,0,0,0.45);">
        <div class="storycontent">
          <div class="titlecn">制茶工艺</div>
          <div class="titleen">Tea making techniques</div>
          <p>原料与工艺的完美融合</p>
          <p>技术与艺术的美妙交融配比</p>
          <p>方能成就一款代表作</p>
          <div class="btn" @click="toCraft">Explore</div>
        </div>
      </div>
    </div>
    <div class="black"></div>
    <Footer id="foot" />
  </div>
</template>

<script>
// @ is an alias to /src
// import Title from "@/components/Title.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    // Title,
    Footer
  },
  data() {
    return {
      dataHeight: "5.625rem",
      navlist: [
        {
          title: "首页",
          id: 0,
          key: "/"
        },
        {
          title: "走进生来",
          id: 1,
          key: "/story"
        },
        {
          title: "产品介绍",
          id: 2,
          key: "/product"
        },
        {
          title: "制茶工艺",
          id: 3,
          key: "/craft"
        }
        // {
        //   title: "中邮设备",
        //   id: 4,
        //   key: "/",
        // },
      ],
      swiperList: [
        require("../assets/img/swiper1.jpg"),
        require("../assets/img/swiper2.jpg"),
        require("../assets/img/swiper3.jpg"),
        require("../assets/img/swiper4.png")
      ],
      isSelect: 0
    };
  },
  methods: {
    toStory() {
      this.$router.push("/story");
    },
    toProduct() {
      this.$router.push("/product");
    },
    toCraft() {
      this.$router.push("/craft");
    },
    navTap(index) {
      this.isSelect = index;
    },
    goto(key) {
      // this.$el.querySelector(key).scrollIntoView({
      //   behavior: "smooth", // 平滑过渡
      //   block: "start", // 上边框与视窗顶部平齐
      // });
      this.$router.push(key);
    },
    gotoself(key) {
      this.$el.querySelector(key).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start" // 上边框与视窗顶部平齐
      });
    },
    move(i) {
      this.isSelect = i;
    }
  }
};
</script>
<style lang="less" scoped>
.common {
  margin: 0 auto;
}
.homelogo {
  height: 146px;
  padding: 30px 0;
  background: #edb55b;
  text-align: center;
  img {
    height: 146px;
    width: 104px;
  }
}
.top {
  width: 100%;
  height: 1080px;
  // margin: 0 0 38px 0;
  position: relative;
  overflow: hidden;
  .arrow {
    width: 50px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 99;
    cursor: pointer;
  }
  .el-carousel {
    height: 100%;
  }
  .el-carousel__item {
    img {
      width: 100%;
      height: 100%;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
  .topleft {
    width: 415px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    text-align: center;
    z-index: 99;
    img {
      width: 100px;
      height: 140px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    // opacity: 0.8;
    .nav {
      color: #fff;
      margin-top: 20px;

      a {
        display: block;
        text-align: center;
        line-height: 100px;
        font-size: 32px;
        cursor: pointer;
        transition: 0.5s;
        text-decoration: none;
        color: #fff;
      }
      a:visited {
        color: #fff;
      }
      a:hover {
        color: #edb55b;
        font-size: 34px;
      }
    }
  }
}
.story {
  width: 100%;
  height: 1079px;
  // margin: 0 0 38px 0;
  position: relative;
  .arrow {
    width: 50px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
    filter: brightness(70%);
    transition: 1.5s;
  }
  img:hover {
    filter: brightness(150%);
  }
  .storyright {
    width: 822px;
    position: absolute;
    top: 0;
    right: 0;
    height: 1079px;
    background: rgba(0, 0, 0, 0.8);
    // opacity: 0.8;
    font-weight: 300;
    .storycontent {
      margin-top: 213px;
      margin-left: 133px;
      color: #fff;
      font-family: source serif;
      font-size: 27px;
      .titlecn {
        font-size: 52px;
        font-family: "source serif Bold";
      }
      .titleen {
        font-size: 34px;
        margin-bottom: 60px;
        font-family: "source serif light";
      }
      p {
        line-height: 70px;
      }
      .btn {
        margin-top: 160px;
        width: 288px;
        height: 65px;
        text-align: center;
        background: #ecb94b;
        line-height: 65px;
        font-size: 39px;
        font-weight: 300;
        cursor: pointer;
        opacity: 0.7;
        transition: 0.7s;
      }
      .btn:hover {
        opacity: 1;
      }
    }
  }
}
.black {
  height: 80px;
  background: #000;
}
</style>
